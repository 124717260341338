import React from "react";
import { Button } from "../Buttons/Button";
import  SliderMain  from "../Slider_main/SliderMain"
import "./main.css"
const Main =()=>{
    return (
        <div className="card_home">
            <SliderMain/>
        </div>
    )
}
export default Main;