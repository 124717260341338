import React from "react";
import TextoFooter from "../../UI/TextoFooter/TextoFooter"
function Footer() {
  return(
    <footer className="footer">
      <TextoFooter/>
    </footer>
  )
}
export default Footer;
