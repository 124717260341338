import React from "react";
import "./integrantes.css"
function integrantes() {
    return (
        <div className="Integrantes">
            <h4 className="integrantes">Desarrolladores</h4>
            <p className="nombres">Mónica Yiseth Ardila Agudelo</p>
            <p className="nombres">Elver Alexis Carreño Suarez</p>
            <p className="nombres">Santigo Diaz Carvera </p>
            <p className="nombres">Darwin Tusarma Taba</p>
        </div>
    )
}
export default integrantes;